<template>
  <div class="flex flex-col space-y-7">
    <div class="flex-1 min-h-screen">
      <m-header />
      <main>
        <slot />
      </main>
      <m-footer />
    </div>
  </div>
</template>

<style>
.overflow-body main,
.overflow-body footer {
  display: none !important;
  max-width: 0 !important;
  max-height: 0 !important;
}
</style>
