<script lang="ts">
import { ref } from "vue";
import Logo from "~/assets/svgs/logo.svg";
export default {
  setup() {
    const IsMenuActive = ref(false);
    function onClick() {
      if (IsMenuActive.value) {
        IsMenuActive.value = false;
      } else {
        IsMenuActive.value = true;
      }
    }
    return {
      LOGO: Logo,
      onClick,
      IsMenuActive,
    };
  },
};
</script>

<template>
  <Body v-if="IsMenuActive" class="overflow-body"></Body>
  <header class="bg-main-photo bg-center h-full w-full bg-cover">
    <section
      class="w-full mobile-menu relative"
      :class="
        IsMenuActive
          ? 'bg-white h-screen overflow-auto fixed z-10 lg:overflow-hidden'
          : 'bg-gradient-to-b h-167 lg:h-169 from-brunswick-green'
      "
    >
      <div
        class="container mx-auto p-4"
        :class="!IsMenuActive ? 'w-full h-full' : 'min-h-54 max-h-54'"
      >
        <section
          class="grid grid-cols-13 mt-8"
          :class="IsMenuActive ? 'lg:mt-16' : 'lg:mt-11'"
        >
          <nuxt-link
            @click="IsMenuActive = false"
            to="/"
            class="col-start-1 col-end-5"
            :class="
              !IsMenuActive
                ? 'lg:w-35 lg:h-35  lg:col-end-3'
                : 'lg:w-25 lg:h-25 lg:col-end-2'
            "
          >
            <img
              :src="LOGO"
              class="w-23 h-23"
              :class="!IsMenuActive ? 'lg:w-35 lg:h-35' : 'lg:w-25 lg:h-25'"
            />
          </nuxt-link>
          <div
            class="flex flex-col justify-center space-y-2 w-full lg:w-114"
            :class="
              !IsMenuActive
                ? 'col-start-6 ml-4 lg:ml-0 col-end-10 lg:col-start-3 lg:col-end-8'
                : 'col-start-6 col-end-10 ml-4 lg:col-start-2 lg:col-end-7'
            "
          >
            <nuxt-link to="/" @click="IsMenuActive = false">
              <div
                class="text-white font-sans text-lg lg:text-xl2"
                :class="IsMenuActive ? 'text-dark-green' : 'text-white'"
              >
                {{ $t("Министерство просвещения Республики Абхазия") }}
              </div>
            </nuxt-link>

            <div
              class="font-serif text-lg hidden lg:block"
              :class="IsMenuActive ? 'text-dark-green' : 'text-white'"
              v-if="!IsMenuActive"
            >
              {{ $t("Официальный сайт Министерства просвещения Республики Абхазия") }}
            </div>
          </div>

          <div
            class="hidden lg:flex flex-row space-x-10 pl-17 font-serif items-center col-start-8 col-end-11"
            :class="IsMenuActive ? 'text-dark-green' : 'text-white'"
            v-if="!IsMenuActive"
          >
            <nuxt-link to="/pressa/accreditation" class="whitespace-nowrap">{{
              $t("Аккредитация")
            }}</nuxt-link>

            <nuxt-link to="/about/staff/">{{ $t("Руководство") }}</nuxt-link>

            <nuxt-link to="/contacts">{{ $t("Контакты") }}</nuxt-link>
          </div>
          <div
            v-if="IsMenuActive"
            class="my-auto w-full mt-8 lg:mt-auto row-start-2 lg:row-start-1 col-start-1 col-end-12 lg:col-start-7 lg:col-end-11 lg:w-129"
          >
            <m-search-field />
          </div>
          <div class="flex items-center col-start-10 lg:col-start-12 lg:col-end-13">
            <m-burger
              @click="onClick"
              :isMenuActive="IsMenuActive"
              :color="!IsMenuActive ? '#FFFFFF' : '#000000'"
            />
          </div>
        </section>
        <section
          class="absolute bottom-7 md:w-184"
          :class="IsMenuActive ? 'text-dark-green' : 'text-white'"
          v-if="!IsMenuActive"
        >
          <div class="text-base lg:text-xl2 font-serif mt-4">
            {{ $t("Центральный орган государственного управления") }}
          </div>
        </section>
      </div>
      <m-extended-menu v-if="IsMenuActive" />
    </section>
  </header>
</template>

<style lang="scss">
@media screen and (max-width: 768px) {
  .overflow-body {
    max-height: 100vh !important;
    max-width: 100vw !important;
    overflow: hidden !important;
  }

  .mobile-menu {
    display: flex;
    flex-direction: column;

    // * {
    //   display: block;
    //   position: relative;
    // }
  }
}
</style>
